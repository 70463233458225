const rethrownError = (err: Error, newMessage = err.message) => {
  const newError = new Error();
  err.message = newMessage;
  for (const stack of newError.stack.split(/\n/).slice(3, -1)) {
    if (!err.stack.includes(stack)) err.stack += `\n${stack}`;
  }
  return err;
};

type Errorish = Error | string;

export default function logError(
  err: Errorish,
  { rethrowAs, errorInfo = {} }: { errorInfo?: Record<string, any>; rethrowAs?: Errorish } = {}
) {
  if (typeof err === 'string') err = new Error(err);
  if (process.env.DEBUG) console.error(err);

  if (rethrowAs)
    err = rethrownError(err, rethrowAs instanceof Error ? rethrowAs.message : rethrowAs);

  const newrelicArgs: Record<string, any> = errorInfo;
  if ('path' in err) newrelicArgs.apiPath = err.path;
  if ('body' in err) newrelicArgs.apiResponse = err.body;

  // client side only
  if (typeof window !== 'undefined' && window['newrelic']) {
    if (Object.keys(newrelicArgs).length > 0) {
      window['newrelic'].noticeError(err, newrelicArgs);
    } else {
      window['newrelic'].noticeError(err);
    }
  }
}
