import { apiFetch } from '@nrdy-marketing-engine/api-fetch';
import { ApiFetchError } from '@nrdy-marketing-engine/api-fetch/src/api-fetch';
import { UserContract } from '@nrdy-marketing-engine/cart/@types/contract';
import { logError } from '@nrdy-marketing-engine/error-handling';

interface getContractsPayload {
  auth_token?: string;
  uuid: string;
}

export const getContracts = async ({
  auth_token,
  uuid,
}: getContractsPayload): Promise<UserContract[]> => {
  const endpoint = `/vtwa/v5/clients/${uuid}/contracts`;
  return apiFetch<UserContract[]>(endpoint, {
    token: auth_token,
  })
    .then((contracts) => contracts as any)
    .catch((err) => {
      if (err instanceof ApiFetchError && err.response.status.toString().startsWith('4')) {
        return null;
      }
      logError(err);
    });
};

export default getContracts;
