import React from 'react';
import logError from './log-error';

interface Props {
  children: React.ReactNode;
  fallbackComponent?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * Catches render errors from components further down and prevents them from bubbling up further.
 */
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, { errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent || null;
    }

    return this.props.children;
  }
}
