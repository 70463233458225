import { Client } from '@nrdy-marketing-engine/cart/@types/cart-state';
import getContracts from '@nrdy-marketing-engine/cart/src/api/user/contracts/get-contracts';
import { useCallback, useEffect, useState } from 'react';
import { AvailableUserCreationTypes } from '../@types/available-user-creation-types';
import type { UserContextInterface } from '../context';
import { UserController } from '../user-controller';

const userController = new UserController();

function useUser(defaults?: UserContextInterface): UserContextInterface {
  const [user, setUser] = useState(defaults?.user);
  const [loading, setLoading] = useState(true);
  const [refetchUser, setRefetchUser] = useState(false);
  const [clientID, setClientID] = useState(defaults?.clientID);
  const [contracts, setContracts] = useState([]);

  const convertUserAndStore = useCallback(() => {
    return userController
      .usersMeConvert()
      .then((data) => {
        setLoading(true);
        setRefetchUser(true);

        return data;
      })
      .catch((err) => {
        setLoading(false);

        console.error(err);
        throw err;
      });
  }, []);

  const createUserAndStore = useCallback((client: Client, type?: AvailableUserCreationTypes) => {
    return userController
      .createAccount(client, undefined, type)
      .then((data) => {
        setLoading(true);
        return data;
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        throw err;
      });
  }, []);

  const fetchUserAndStore = useCallback(() => {
    return userController
      .usersMeGet()
      .then((user) => updateStateUser(user))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  const refetchUserAndStore = useCallback(() => fetchUserAndStore(), [fetchUserAndStore]);

  const signInAndStore = useCallback(({ email, password }: Partial<Client>) => {
    return userController
      .signIn({ email, password })
      .then((data) => {
        setLoading(true);
        setRefetchUser(true);

        return data;
      })
      .catch((err) => {
        setLoading(false);

        console.error(err);
        throw err;
      });
  }, []);

  const updateStateUser = async (user) => {
    if (user?.entity) {
      const clientId = user.entity.client_uuid;
      setUser(user);
      setClientID(clientId);
      setContracts(await getContracts({ uuid: clientId }));
    }
  };

  useEffect(
    function _refetchUser() {
      if (refetchUser) {
        setRefetchUser(false);
        fetchUserAndStore();
      }
    },
    [refetchUser, fetchUserAndStore]
  );

  useEffect(
    function _initialUserSetup() {
      if (!user && loading) fetchUserAndStore();
    },
    [user, loading, fetchUserAndStore]
  );

  return {
    clientID,
    contracts,
    convertUserAndStore,
    createUserAndStore,
    loading,
    refetchUserAndStore,
    signInAndStore,
    user,
  };
}

export default useUser;
