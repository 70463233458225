import vtRequestId from '@nrdy-marketing-engine/api-fetch/src/vt-request-id';
import { track } from '@nrdy-marketing-engine/tracking/src/track';

function trackApiFetch(category: string, target: string, label = target, metaData = {}) {
  if (typeof window === 'undefined') return;
  track('apiFetch', {
    category,
    label,
    newrelicMetadata: metaData,
    request_id: vtRequestId(),
    target,
  }).catch((err) => {
    // this only happens during `npm run build`. Otherwise we should be good.
    /* istanbul ignore next */
    console.warn('Unable to track apiFetch event', err);
  });
}

export default trackApiFetch;
