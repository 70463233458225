const JSON_OBFUSTATE_FIELDS = [
  'name',
  'address',
  'password',
  'email',
  'first_name',
  'last_name',
  'phone',
  'telephone',
  'token',
];

function obsfucationRegexBuilder(params: string[]) {
  let str = params.reduce((acc, param) => {
    return (acc += `("(${param}.*?)":(.+?))[,}]|`);
  }, '');
  str = str.substring(0, str.length - 1);
  const regex = new RegExp(`${str}`, 'g');
  return regex;
}

export default function obfuscateString(str: string): string {
  try {
    const regex = obsfucationRegexBuilder(JSON_OBFUSTATE_FIELDS);
    return str.replace(regex, (match) => {
      const value = match.substring(match.indexOf(':'), match.length - 1);
      match = match.replace(value, ':**MASKED**');
      return match;
    });
  } catch (e) {
    console.warn('Error obfuscating string:', e);
    return str;
  }
}
